import { useEffect, useState } from "react";
import { finished } from "stream-browserify";

const Horde = () => {
  const [data, setData] = useState(null);
  const [workers, setWorkers] = useState([]);
  const [models, setModels] = useState([]);

  const [error, setError] = useState(null);
  const [textValue, setTextValue] = useState();
  const [prompt, setPrompt] = useState("");
  const [promptID, setPromptID] = useState(null);
  const [image, setImage] = useState("");
  const [waitTime, setWaitTime] = useState("");

  const resetStates = (e) => {
    setData(null);
    setWorkers([]);
    setModels([]);
    setError(null);
    setPromptID(null);
    setImage("");
    handleWorkerRequest(e);
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("apikey", "jsowp0fF854Jb0F5YixFMA");
  //     try {
  //       const response = await fetch(
  //         `https://stablehorde.net/api/v2/find_user`,
  //         {
  //           method: "GET",
  //           headers: headers,
  //         }
  //       );
  //       const json = await response.json();
  //       setData(json);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const placeHolderImage = () => {};

  const handlePromptInputChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleWorkerRequest = async (e) => {
    e.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("apikey", "jsowp0fF854Jb0F5YixFMA");
    try {
      const response = await fetch(`https://stablehorde.net/api/v2/workers`, {
        method: "GET",
        headers: headers,
      }).then((response) => {
        if (!response.ok) {
          handleWorkerRequest(e);
        }
      });
      const json = await response.json();
      const workers = json.map((worker) => worker.id);
      setWorkers(workers);
      console.log(workers);
    } catch (error) {
      console.log(error);
    }
    handleModelRequest(e);
  };

  const handleModelRequest = async (e) => {
    e.preventDefault();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("apikey", "jsowp0fF854Jb0F5YixFMA");
    try {
      const response = await fetch(
        `https://stablehorde.net/api/v2/status/models`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      const models = json.map((model) => model.name);
      setModels(models);
      console.log(models);
    } catch (error) {
      console.log(error);
    }
    handlePromptSubmit(e);
  };

  const handlePromptSubmit = async (e) => {
    e.preventDefault();
    const min = 0;
    const max = 10000;
    const seedValue = Math.round(
      Math.floor(Math.random() * (max - min) + min)
    ).toString();
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("apikey", "jsowp0fF854Jb0F5YixFMA");
    try {
      const response = await fetch(
        `https://stablehorde.net/api/v2/generate/async`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            prompt: prompt,
            params: {
              sampler_name: "k_lms",
              toggles: [1, 4],
              cfg_scale: 5,
              denoising_strength: 0.75,
              seed: seedValue,
              height: 512,
              width: 512,
              seed_variation: 1,
              post_processing: ["GFPGAN"],
              karras: false,
              steps: 30,
              n: 1,
            },
            nsfw: false,
            trusted_workers: false,
            censor_nsfw: false,
            workers: workers[0],
            models: models["stable_diffusion"],
            r2: false,
          }),
        }
      );
      const json = await response.json();
      setPrompt("");
      const promptID = json["id"];
      console.log(promptID);
      setPromptID(promptID);
      handleStatusUpdate(promptID);
    } catch (error) {
      setError(error);
    }
  };

  const handleStatusUpdate = (promptID) => {
    const intervalId = setInterval(async () => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      try {
        const response = await fetch(
          `https://stablehorde.net/api/v2/generate/check/${promptID}`,
          {
            crossDomain: true,
            method: "GET",
            headers: headers,
          }
        );
        const json = await response.json();
        console.log(json["done"]);
        setWaitTime(json["wait_time"]);
        if (json["done"] === true) {
          clearInterval(intervalId);
          const images = json["done"];
          handleImageDisplay(images, promptID);
        }
        // setImage(images);
        // console.log(images);
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  };

  const handleImageDisplay = async (images, promptID) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    try {
      const response = await fetch(
        `https://stablehorde.net/api/v2/generate/status/${promptID}`,
        {
          crossDomain: true,
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      const imageUrl =
        "data:image/webp;base64," + json["generations"][0]["img"];
      setImage(imageUrl);
      console.log(imageUrl);
    } catch (error) {
      console.log(error);
    }
  };

  // Render the data or a loading message
  return (
    <div
      data-theme="forest"
      className="flex items-center justify-center min-h-screen w-full bg-transparent"
    >
      <div className="card w-96 bg-base-100 shadow-xl">
        <figure className="w-96">
          {image ? (
            <>
              <img className="object-fill" src={image} alt={prompt} />
            </>
          ) : (
            <div className="flex justify-center items-center w-full h-96 bg-gray-300 rounded sm:w-96 dark:bg-gray-700 animate-pulse">
              {!waitTime ? (
                <svg
                  className="w-12 h-12 text-gray-200"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 640 512"
                >
                  <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                </svg>
              ) : (
                <div
                  className="radial-progress text-primary"
                  style={{ "--value": waitTime*10 }}
                >
                  {waitTime}s
                </div>
              )}
            </div>
          )}
        </figure>
        <div className="card-body">
          <form
            onSubmit={resetStates}
            className="justify-center items-center w-86"
          >
            <label htmlFor="prompt-input" className="mx-2"></label>
            <input
              value={prompt}
              onChange={handlePromptInputChange}
              type="text"
              placeholder="enter prompt..."
              className="input input-bordered"
              id="prompt-input"
            />
            <button type="submit" className="btn btn-accent mx-2">
              send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Horde;
