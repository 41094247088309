import React, { memo, useMemo, useRef, useState } from "react";
import "./App.css";
import { useControls, fill, Leva, button, folder } from "leva";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  OrbitControls,
  OrthographicCamera,
  Stage,
  useGLTF,
  AccumulativeShadows,
  Grid,
  RandomizedLight,
} from "@react-three/drei";

const Box = () => {
  const mesh = useRef(null);
  const { nodes } = useGLTF("/GLB/assets-compressed.gltf");
  const [generated, setGenerated] = useState();
  const names = Object.keys(nodes);
  names.shift(0);

  // const {scale} = useControls("Scale",{scale: 1})

  //DropDown code
  // const object = useControls({
  //   value: { options: { Cylinder: 1, Sphere: 2 } },
  // });

  const { object } = useControls("Object", {
    object: {
      value: 4,
      min: 0,
      max: names.length - 1,
      step: 1,
    },
  });
  const { scale } = useControls("Object Scale", {
    scale: {
      value: 2.2,
      min: 0,
      max: 5,
      step: 0.1,
    },
  });

  const colorVal = ["hotpink", "orange", "lightblue", "green", "red", "lime"];
  const randomColor = colorVal[Math.floor(Math.random() * colorVal.length)];
  const { color } = useControls({ color: randomColor }); //useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.001));
  const { xPos } = useControls({
    xPos: { value: 0, min: -5, max: 5, step: 0.1 },
  });
  const { yPos } = useControls({
    yPos: { value: 0, min: -5, max: 5, step: 0.1 },
  });
  const { zPos } = useControls({
    zPos: { value: 0, min: -5, max: 5, step: 0.1 },
  });

  const [{ text }, setText] = useControls(() => ({
    ai_Texture: folder({
      text: "Enter Prompt....",
    }),
  }));

  const mintButton = useControls({
    ai_Texture: folder({
      sendPrompt: button(
        (get) => sendPrompt(),
        (get) =>
          alert(
            "Minting has begun, follow steps in your wallet, this may take a few moments..."
          )
      ),
      applyTexture: button((get) => sendPrompt()),
    }),
  });

  const sendPrompt = () => {
    alert("please wait while we generate your texture...")
  }
  function mint() {
    console.log("Minting...");
  }

  useFrame(() => {
    mesh.current.x += 0.01;
  });

  console.log(names[object]);
  return (
    <mesh
      ref={mesh}
      position={[xPos, yPos, zPos]}
      scale={scale}
      castShadow
      receiveShadow
      color={color}
      geometry={nodes[names[object]].geometry}
    >
      <meshStandardMaterial attach="material" color={color} />
    </mesh>
  );
};

function App() {
  const colorVal = ["hotpink", "orange", "lightblue", "green", "red", "lime"];
  const randomColor = colorVal[Math.floor(Math.random() * colorVal.length)];
  const { color } = useControls("Background", { color: randomColor });
  //  const { toggle } = useControls({ toggle: false });

  return (
    <>
      <Canvas shadows style={{ background: color }}>
        <Stage rembrandt>
          <OrthographicCamera makeDefault zoom={100} />
          <OrbitControls target={[0, 2, 0]} panSpeed="0" />
          <Box />
        </Stage>
      </Canvas>
      <div className="h-full w-full bg-transparent z-10">
        <div className="absolute top-72 right-20">
          <Leva fill />
        </div>
      </div>
    </>
  );
}

export default App;
